import collegesParsed from "./colleges-parsed.json";
import { levDist, joinWithCommaAnd, hashCode } from "../string";
import { mulberry32 } from "../random";

export function sortCollegesByRanking(a, b) {
  a = a.name;
  b = b.name;
  if (a in collegesParsed && b in collegesParsed)
    return collegesParsed[a].rank - collegesParsed[b].rank;
  if (a in collegesParsed) return 1;
  return -1;
}

export function getAlsoAcceptedAtContent(collegeResults, prefix) {
  var acceptedSchools = collegeResults.filter((college) => college.result == "Accepted");
  acceptedSchools.sort(sortCollegesByRanking);
  if (acceptedSchools.length > 0) {
    return prefix + joinWithCommaAnd(acceptedSchools.map((e) => getName(e.name)));
  } else {
    return "";
  }
}

export function getColor(college) {
  const data = find(college);
  if (!data) return "#000000";
  if (data.color) return data.color;
  const hash = hashCode(college);
  const rng = mulberry32(hash);
  const h = rng() * 360;
  const s = rng() * 10 + 90;
  const l = rng() * 10 + 30;
  const color = `hsl(${h},${s}%,${l}%)`;
  return color;
}

export function find(query) {
  query = query?.trim();
  if (!query) return null;

  if (query.toLowerCase() === "columbia" || query.toLowerCase() === "columbia university")
    query = "Columbia University in the City of New York";
  const obj = collegesParsed[query];
  if (obj) return obj;

  const keys = Object.keys(collegesParsed).sort((a, b) => levDist(a, query) - levDist(b, query));
  const result = keys[0];
  collegesParsed[query] = collegesParsed[result]; // Memoize it
  return collegesParsed[result];
}

export function findClosestName(query) {
  query = query.trim();
  if (!query) return null;
  if (query.toLowerCase() === "columbia" || query.toLowerCase() === "columbia university")
    query = "Columbia University in the City of New York";
  if (query in collegesParsed) return query;

  const keys = Object.keys(collegesParsed).sort((a, b) => levDist(a, query) - levDist(b, query));
  return keys[0];
}

export function getName(query) {
  return find(query)?.name ?? query;
}

export const colleges = collegesParsed;
